<template>
  <v-container fluid>
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Saving</div>
    </v-overlay>
    <v-card outlined>
      <v-toolbar primary-title color="primary" dark> Child/Children </v-toolbar>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent>
            <template v-for="(task, i) in basketChildren">
              <v-row
                justify="center"
                align="center"
                :class="{ mycolor: task.duplicate }"
                :key="i"
              >
                <v-col cols="12" lg="5">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="task.name"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      outlined
                      hide-details="auto"
                      label="Name of child"
                      v-model="task.name"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-menu
                    v-model="task.menuDob"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.dob"
                        rules="required"
                      >
                        <v-text-field
                          v-model="task.dob"
                          label="Date of birth"
                          readonly
                          outlined
                          hide-details="auto"
                          :error-messages="errors"
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="task.dob"
                      @input="task.menuDob = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" lg="2">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="task.birthplace"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      outlined
                      v-model="task.birthplace"
                      :error-messages="errors"
                      label="Birthplace"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="2">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="task.relationship"
                    rules="required|alpha"
                  >
                    <v-text-field
                      hide-details="auto"
                      outlined
                      v-model="task.relationship"
                      :error-messages="errors"
                      label="Relationship"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="1" class="text-center">
                  <v-slide-y-reverse-transition>
                    <v-btn
                      icon
                      v-if="i + 1 === basketChildren.length"
                      color="success"
                      @click="newRow()"
                      :disabled="!valid"
                    >
                      <v-icon>mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-slide-y-reverse-transition>
                  <v-slide-y-reverse-transition>
                    <v-btn
                      v-if="i + 1 >= 2"
                      icon
                      color="red"
                      @click="removeRow(i, task.id)"
                    >
                      <v-icon> mdi-delete-outline </v-icon>
                    </v-btn>
                  </v-slide-y-reverse-transition>
                </v-col>
              </v-row>
            </template>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="ml-2">
        <v-btn
          @click="addChildren"
          class="font-weight-bold"
          fixed
          bottom
          color="success"
          :disabled="!valid"
        >
          save changes
        </v-btn>
      </v-card-actions>
    </v-card>
    <Response v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import { defineComponent, provide, reactive, toRefs, watch } from "vue";

  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    digits,
    regex,
    alpha_dash,
    alpha_spaces,
    required,
    alpha,
  } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("alpha", {
    ...alpha,
    message: "{_field_} must be alphabet",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("regex", {
    ...regex,
    message: "{_field_}  must be ten digits starting with zero(0)",
  });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { Response, ValidationProvider, ValidationObserver },
    setup() {
      const { userChildren } = useActions(["userChildren"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const spouseChildrenForm = reactive({
        observer: null,
        valid: true,
        basketChildren: [...getters_single_staff.value.child],
        menuDob: false,
        msgBody: null,
        msgIcon: null,
        color: null,
        overlay: false,
        actionResponse: false,
        deletedItems: [],
      });

      const {
        basketChildren,
        observer,
        msgBody,
        msgIcon,
        color,
        actionResponse,
        overlay,
        deletedItems,
        valid,
      } = toRefs(spouseChildrenForm);

      const addChildren = async () => {
        actionResponse.value = false;

        await observer.value.validate().then((result) => {
          if (valid.value === false) return;
          overlay.value = true;
          if (result) {
            let data = {
              children: basketChildren.value,
              deletedItems: deletedItems.value,
              user_id: getters_single_staff.value.id,
            };
            basketChildren.value.forEach((item) => delete item.menuDob);

            userChildren(data)
              .then(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "success";
                msgBody.value = "Your changes have been saved.";
                msgIcon.value = "mdi-check-circle";
              })
              .catch(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgIcon.value = "mdi-close-circle";
              });
          }
        });
      };

      const newRow = () => {
        basketChildren.value.push({
          name: "",
          dob: "",
          birthplace: "",
          relationship: "",
          id: null,
          user_id: getters_single_staff.value.id,
        });
      };

      const removeRow = (i, taskId) => {
        basketChildren.value.splice(i, 1);
        if (taskId) deletedItems.value.push(taskId);
      };

      provide("color", color);

      watch(
        basketChildren,
        () => {
          actionResponse.value = false;
          const uniqueItems = [];
          basketChildren.value.filter((item) => {
            if (
              uniqueItems.find(
                (i) =>
                  i.name == item.name &&
                  i.dob == item.dob &&
                  i.birthplace == item.birthplace &&
                  i.relationship == item.relationship
              )
            ) {
              item["duplicate"] = true;
              valid.value = false;
              actionResponse.value = true;
              color.value = "error";
              msgBody.value = "Duplicate Found";
              msgIcon.value = "mdi-close-circle";
              return true;
            }
            uniqueItems.push(item);
            valid.value = true;
            delete item["duplicate"];
            return false;
          });
        },
        { deep: true }
      );

      return {
        ...toRefs(spouseChildrenForm),
        addChildren,
        newRow,
        removeRow,
      };
    },
  });
</script>

<style scoped>
  .mycolor {
    border: 1px solid red;
    border-radius: 5px;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
