<template>
  <v-container fluid>
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Saving</div>
    </v-overlay>
    <v-card outlined>
      <v-toolbar color="primary" dark primary-title> Spouse </v-toolbar>

      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent>
            <v-slide-y-transition>
              <div class="mt-3">
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Spouse"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        class="rounded-0"
                        label="Name of spouse"
                        v-model="spouseName"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-menu
                      v-model="menuSpouse"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Spouse date of birth"
                          rules="required"
                        >
                          <v-text-field
                            v-model="spouseDob"
                            label="Date of birth"
                            readonly
                            outlined
                            class="rounded-0"
                            hide-details="auto"
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="spouseDob"
                        @input="menuSpouse = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Hometown"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Hometown"
                        class="rounded-0"
                        v-model="spouseHometown"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile number 1"
                      :rules="{
                        required: true,
                        digits: 10,
                        regex: '^(0)\\d{9}$',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Phone number 1"
                        class="rounded-0"
                        v-model="spouseNumber1"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile number 2"
                      :rules="{
                        regex: spouseNumber2 ? '^(0)\\d{9}$' : '',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Phone number 2 (optional)"
                        v-model="spouseNumber2"
                        class="rounded-0"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Date of birth"
                      rules="required"
                    >
                      <v-textarea
                        hide-details="auto"
                        outlined
                        class="rounded-0"
                        label="Address"
                        :error-messages="errors"
                        v-model="spouseAddress"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </div>
            </v-slide-y-transition>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions class="ml-2">
        <v-btn
          fixed
          bottom
          class="font-weight-bold"
          color="success"
          @click="sendData"
        >
          save changes
        </v-btn>
      </v-card-actions>
    </v-card>
    <Response v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import { defineComponent, onMounted, provide, reactive, toRefs } from "vue";

  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";

  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { digits, regex, required } from "vee-validate/dist/rules";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("regex", {
    ...regex,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { Response, ValidationObserver, ValidationProvider },
    setup() {
      const { userSpouse } = useActions(["userSpouse"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const spouseForm = reactive({
        observer: null,
        valid: true,
        menuSpouse: false,
        spouseName: null,
        spouseDob: null,
        spouseAddress: null,
        spouseNumber1: null,
        spouseNumber2: null,
        spouseHometown: null,
        basketSpouse: null,
        menuDob: false,
        msgBody: null,
        msgIcon: null,
        color: null,
        overlay: false,
        actionResponse: false,
      });

      const {
        observer,
        spouseDob,
        spouseName,
        spouseAddress,
        spouseNumber1,
        spouseNumber2,
        spouseHometown,
        basketSpouse,
        msgBody,
        msgIcon,
        color,
        actionResponse,
        overlay,
      } = toRefs(spouseForm);

      onMounted(() => {
        spouseDob.value = getters_single_staff.value.spouse.dob;
        spouseName.value = getters_single_staff.value.spouse.spouse;
        spouseAddress.value = getters_single_staff.value.spouse.address;
        spouseNumber1.value = getters_single_staff.value.spouse.phone_number1;
        spouseNumber2.value = getters_single_staff.value.spouse.phone_number2;
        spouseHometown.value = getters_single_staff.value.spouse.hometown;
      });

      const sendData = async () => {
        actionResponse.value = false;

        await observer.value.validate().then((result) => {
          overlay.value = true;
          if (result) {
            basketSpouse.value = {
              user_id: getters_single_staff.value.id,
              spouse: spouseName.value,
              dob: spouseDob.value,
              address: spouseAddress.value,
              phone_number1: spouseNumber1.value,
              phone_number2: spouseNumber2.value,
              hometown: spouseHometown.value,
            };
            userSpouse(basketSpouse.value)
              .then(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "success";
                msgBody.value = "Your changes have been saved.";
                msgIcon.value = "mdi-check-circle";
              })
              .catch(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgIcon.value = "mdi-close-circle";
              });
          }
        });
      };

      provide("color", color);

      return {
        ...toRefs(spouseForm),
        sendData,
        getters_single_staff,
      };
    },
  });
</script>
