<template>
  <v-container fluid>
    <PageHeader>
      <span slot="courses" class="font-weight-light">STAFF </span> LIST
    </PageHeader>

    <v-card>
      <v-card-title>
        <v-btn
          class="secondary--text font-weight-bold"
          @click="addNewStaff"
          color="primary"
        >
          CREATE NEW STAFF
        </v-btn>
        <v-spacer></v-spacer>

        <v-text-field
          append-icon="mdi-account-search"
          class="rounded-0"
          hide-details="auto"
          label="Search Staff Info..."
          single-line
          outlined
          v-model="email"
          @click:append="searchStaffBtn"
          @keyup="searchStaffBtn"
        ></v-text-field>
      </v-card-title>

      <!--  <resize-component>
        <template v-slot:table="tableProps">  :height="tableProps.tableHeight - 16"-->
      <v-data-table
        fixed-header
        :headers="headers"
        :items="items"
        class="elevation-0"
        :loading="isLoaded"
        item-key="id"
        disable-pagination
        hide-default-footer
      >
        <template #item.basic="{ item }">
          <div class="d-flex align-center">
            <v-avatar size="40">
              <v-img
                contain
                :src="
                  item.avatar_url
                    ? item.avatar_url
                    : require('@/assets/download.jpg')
                "
                :lazy-src="item.avatar_url"
              ></v-img>
            </v-avatar>
            <div class="ml-5">
              <div
                v-text="`${item.othernames} ${item.lastname}`"
                class="text-uppercase"
              ></div>
              <div v-text="item.corporate_email"></div>
            </div>
          </div>
        </template>

        <template #item.designation="{ item }">
          <template v-if="item.employment">
            <div
              class="subtitle-1"
              v-if="item.employment.staff_position"
              v-text="item.employment.staff_position.position"
            ></div>
            <div v-if="item.employment.organogram">
              <template
                v-if="item.employment.organogram.office_type == 'office'"
              >
                {{
                  `${item.employment.organogram.office_type} of the ${item.employment.organogram.office_name}`
                }}
              </template>
              <template v-else>
                {{
                  `${item.employment.organogram.office_name} ${item.employment.organogram.office_type}`
                }}
              </template>
            </div>
          </template>
        </template>

        <template #item.staffID="{ item }">
          <div v-if="item.employment" class="subtitle-1">
            {{ item.employment.employee_id }}
          </div>
        </template>

        <template #item.contact="{ item }">
          <div class="subtitle-1">
            {{ item.office_number }}
          </div>
        </template>

        <template #item.actions="{ item }">
          <v-menu
            offset-y
            open-on-hover
            transition="slide-x-transition"
            bottom
            right
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                outlined
                color="grey darken-1"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list
              dark
              color="primary darken-2"
              min-width="250"
              tile
              class="py-0"
            >
              <v-list-item-group>
                <v-list-item
                  link
                  @click="viewStaffBtn(item)"
                  v-if="getters_abilities.includes('create_staff_access')"
                >
                  <v-list-item-content>
                    <v-list-item-title>View Staff Details</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
                <v-list-item
                  link
                  v-if="getters_abilities.includes('role_access')"
                  @click="roleStaffBtn(item)"
                >
                  <v-list-item-content>
                    <v-list-item-title>Role/Permission</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-icon>mdi-arrow-right</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>

        <template v-slot:no-data>
          <span class="subheading font-weight-bold">Not available</span>
        </template>
      </v-data-table>

      <v-card v-if="getters_staff.length" v-intersect="intersected">
        <v-progress-linear
          :indeterminate="true"
          v-if="loadingScroll"
        ></v-progress-linear>
      </v-card>
      <!-- </template>
      </resize-component> -->
    </v-card>

    <!-- View Staff -->
    <v-navigation-drawer
      overlay-color="black"
      v-model="openStaff"
      fixed
      :permanent="openStaff"
      right
      width="60%"
      class="shadow"
      clipped
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-btn
          class="ma-2"
          @click="
            openStaff = false;
            basket.pop();
          "
          v-if="basket.length > 1"
          icon
        >
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          @click="
            closeDrawer;
            openStaff = false;
          "
          icon
          class="ma-2"
          to="/staff_management/stafflist"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>

      <ViewStaff v-if="openStaff === true" />
    </v-navigation-drawer>

    <!--Edit Staff -->
    <v-navigation-drawer
      v-model="editStaff"
      fixed
      :permanent="editStaff"
      right
      width="60%"
      class="shadow"
      clipped
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-btn
          class="ma-2"
          @click="
            editStaff = false;
            basket.pop();
          "
          v-if="basket.length > 1"
          icon
        >
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="
            closeDrawer;
            editStaff = false;
          "
          icon
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>

      <component :is="componentCurrent" v-if="editStaff" />
    </v-navigation-drawer>

    <!-- Create New Staff -->
    <v-navigation-drawer
      clipped
      overlay-color="black"
      v-model="newStaff"
      fixed
      :permanent="newStaff"
      right
      width="70%"
      class="shadow"
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-btn
          @click="
            newStaff = !newStaff;
            basket.pop();
          "
          class="ma-2"
          v-if="basket.length > 1"
          icon
        >
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="
            closeDrawer;
            newStaff = false;
          "
          icon
          to="/staff_management/stafflist"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>

      <NewStaff v-if="newStaff" />
    </v-navigation-drawer>

    <!-- Staff Role -->
    <v-navigation-drawer
      clipped
      overlay-color="black"
      v-model="roleStaff"
      fixed
      :permanent="roleStaff"
      right
      width="40%"
      class="shadow"
    >
      <div class="mt-15 d-flex justify-space-between">
        <v-btn
          @click="
            roleStaff = !roleStaff;
            basket.pop();
          "
          class="ma-2"
          v-if="basket.length > 1"
          icon
        >
          <v-icon>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="ma-2"
          @click="
            closeDrawer;
            roleStaff = false;
          "
          icon
          to="/staff_management/stafflist"
        >
          <v-icon>mdi-close-circle</v-icon>
        </v-btn>
      </div>

      <Role v-if="roleStaff" />
    </v-navigation-drawer>
  </v-container>
</template>

<script>
  import PageHeader from "@/components/slots/PageHeader";
  import ViewStaff from "@/components/Staff/ViewStaff";
  import GeneralInfo from "@/components/Staff/Edit/GeneralInfo";
  import EmploymentInfo from "@/components/Staff/Edit/EmploymentInfo";
  import BankInfo from "@/components/Staff/Edit/BankInfo";
  import SpouseInfo from "@/components/Staff/Edit/SpouseInfo";
  import ChildrenInfo from "@/components/Staff/Edit/ChildrenInfo";
  import QualificationInfo from "@/components/Staff/Edit/QualificationInfo";
  import ParentInfo from "@/components/Staff/Edit/ParentInfo";
  import NextOfKin from "@/components/Staff/Edit/NextOfKin";
  import Gratuity from "@/components/Staff/Edit/Gratuity";
  import NewStaff from "@/components/Staff/View/NewStaff";
  import Role from "@/components/Staff/RolePermission/Role";
  import { computed, getCurrentInstance, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import debounce from "@/debounce/debounce";
  import emitter from "@/debounce/emitter";
  import ResizeComponent from "@/components/slots/ResizeComponent.vue";
  // import { uuid } from "vue-uuid";
  export default {
    components: {
      PageHeader,
      ViewStaff,
      GeneralInfo,
      NewStaff,
      EmploymentInfo,
      QualificationInfo,
      BankInfo,
      SpouseInfo,
      ChildrenInfo,
      ParentInfo,
      NextOfKin,
      Gratuity,
      Role,
      ResizeComponent,
    },
    props: ["id"],
    setup(props,) {
      const vm = getCurrentInstance()
      const staffTable = reactive({
        componentCurrent: null,
        openStaff: false,
        editStaff: false,
        newStaff: false,
        roleStaff: false,
        isIntersecting: false,
        items: [],
        page: 1,
        isLoaded: true,
        headers: [
          { text: "STAFF", value: "basic", sortable: true },
          { text: "STAFF ID", value: "staffID", sortable: true },
          { text: "CONTACT", value: "contact", sortable: true },
          { text: "DESIGNATION", value: "designation", sortable: true },
          { text: "ACTIONS", value: "actions", sortable: false },
        ],
        staffName: null,
        levelItem: "",
        deleteResponse: false,
        absolute: true,
        newFacultyText: "",
        basket: [
          {
            department_id: null,
            department_name: "",
            faculty_number: null,
          },
        ],
        loadingScroll: false,
        basketDrawer: [],
        currentId: null,
        email: null,
        observer: null,
        valid: true,
        loading: false,
        namespace: "65f9af5d-f23f-4065-ac85-da725569fdcd",
      });

      const { getStaff } = useActions(["getStaff"]);
      const { getters_staff, getters_staff_meta, getters_abilities } = useGetters(
        ["getters_staff", "getters_staff_meta", "getters_abilities"]
      );

      const {
        // namespace,
        email,
        loading,
        page,
        isIntersecting,
        loadingScroll,
        isLoaded,
        items,
        openStaff,
        basket,
        currentId,
        editStaff,
        newStaff,
        roleStaff,
        componentCurrent,
        staffName,
      } = toRefs(staffTable);

      getStaff({ page: page.value, email: email.value })
        .then(() => {
          isLoaded.value = false;
          items.value = getters_staff.value;

          if (props.id) {
            switch (
              vm.proxy.$route.name) {
              case "StaffRole":
                roleStaff.value = true;
                break;
              case "CurrentStaff":
                openStaff.value = true;
                break;

              default:
                break;
            }
            currentId.value = props.id;
          }
        })
        .finally(() => {
          setTimeout(() => {
            switch (
              vm.proxy.$route.name) {
              case "AddStaff":
                newStaff.value = true;
                break;

              default:
                break;
            }
          });
        });

      const intersected = (entries) => {
        isIntersecting.value = entries[0].isIntersecting;
        if (!isIntersecting.value) {
          loadingScroll.value = true;
          return;
        }
        if (
          getters_staff_meta.value.current_page >=
          getters_staff_meta.value.last_page
        ) {
          loadingScroll.value = false;
          return;
        }

        page.value++;
        getStaff({ page: page.value, email: email.value }).then(() => {
          loadingScroll.value = false;
          items.value = [...new Set([...items.value, ...getters_staff.value])];
        });
      };

      emitter.on("edit-contact", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "GeneralInfo";
        basket.value.push(0);
      });

      emitter.on("edit-employment", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "EmploymentInfo";
        basket.value.push(0);
      });

      emitter.on("edit-spouse", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "SpouseInfo";
        basket.value.push(0);
      });

      emitter.on("edit-children", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "ChildrenInfo";
        basket.value.push(0);
      });

      emitter.on("edit-qualification", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "QualificationInfo";
        basket.value.push(0);
      });

      emitter.on("edit-bank", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "BankInfo";
        basket.value.push(0);
      });

      emitter.on("edit-parent", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "ParentInfo";
        basket.value.push(0);
      });

      emitter.on("edit-kin", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "NextOfKin";
        basket.value.push(0);
      });

      emitter.on("edit-gratuity", (bool) => {
        editStaff.value = bool;
        componentCurrent.value = "Gratuity";
        basket.value.push(0);
      });

      const viewStaffBtn = debounce((staff) => {
        openStaff.value = true;
        currentId.value = staff.id;

        vm.proxy.$router.replace({
          path: `/staff_management/stafflist/:/staff/${currentId.value}`,
        });
      });

      const roleStaffBtn = debounce((staff) => {
        roleStaff.value = true;
        currentId.value = staff.id;
        staffName.value = staff.title_name;
        vm.proxy.$router.replace({
          path: `/staff_management/stafflist/:/role/${currentId.value}`,
        });
      });

      const closeDrawer = computed(() => {
        basket.value = [];
        openStaff.value = false;
        editStaff.value = false;
        newStaff.value = false;
        roleStaff.value = false;
        return false;
      });

      const addNewStaff = () => {
        newStaff.value = true;
        vm.proxy.$router.replace({
          path: `/staff_management/stafflist/:/addstaff`,
        });
      };

      const searchStaffBtn = debounce(() => {
        loading.value = true;
        isLoaded.value = true;
        getStaff({ page: page.value, email: email.value })
          .then(() => {
            loading.value = false;
            if (props.id) {
              switch (
                vm.proxy.$route.name) {
                case "StaffRole":
                  roleStaff.value = true;
                  break;
                case "CurrentStaff":
                  openStaff.value = true;
                  break;

                default:
                  break;
              }
              currentId.value = props.id;
            }
          })
          .catch(() => {})
          .finally(() => {
            setTimeout(() => {
              switch (
                vm.proxy.$route.name) {
                case "AddStaff":
                  newStaff.value = true;
                  break;

                default:
                  break;
              }
            });
            isLoaded.value = false;
          });
      });

      provide("staff_id", currentId);
      provide("staffName", staffName);

      return {
        ...toRefs(staffTable),
        intersected,
        getters_staff,
        viewStaffBtn,
        items,
        closeDrawer,
        addNewStaff,
        getters_abilities,
        roleStaffBtn,
        searchStaffBtn,
      };
    },
  };
</script>
<style scoped>
  .v-data-table >>> td {
    padding: 14px 16px !important;
  }
</style>
