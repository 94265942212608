<template>
  <v-container fluid class="px-0">
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Saving</div>
    </v-overlay>
    <v-card flat>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent ref="form1">
            <v-row>
              <v-col cols="12" lg="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Account Number"
                  rules="required|numeric"
                >
                  <v-text-field
                    hide-details="auto"
                    outlined
                    class="rounded-0"
                    label="Account Number"
                    v-model="accountNumber"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Name of bank"
                  rules="required|alpha_spaces"
                >
                  <v-text-field
                    hide-details="auto"
                    outlined
                    class="rounded-0"
                    label="Name of Bank"
                    v-model="bankName"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="12">
                <validation-provider
                  v-slot="{ errors }"
                  name="Branch name"
                  rules="required|alpha_spaces"
                >
                  <v-text-field
                    hide-details="auto"
                    outlined
                    class="rounded-0"
                    label="Name of branch"
                    v-model="branch"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>

            <v-footer app color="transparent">
              <v-btn
                class="font-weight-bold"
                color="primary"
                @click="sendData"
                :disabled="disabled"
              >
                save changes
              </v-btn>
            </v-footer>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>

    <Response v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import { defineComponent, provide, reactive, toRefs, watch } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import { watchItems } from "@/debounce/all";

  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import {
    required,
    alpha_dash,
    alpha_spaces,
    numeric,
  } from "vee-validate/dist/rules";

  extend("email", { ...numeric, message: "Email must be valid" });

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { Response, ValidationProvider, ValidationObserver },
    setup() {
      const { userBank } = useActions(["userBank"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);

      const bankForm = reactive({
        bankName: null,
        branch: null,
        accountNumber: null,
        observer: null,
        actionResponse: false,
        msgBody: null,
        msgIcon: null,
        color: null,
        overlay: false,
        disabled: true,
      });

      const {
        observer,
        bankName,
        accountNumber,
        branch,
        msgBody,
        msgIcon,
        color,
        actionResponse,
        overlay,
        disabled,
      } = toRefs(bankForm);

      bankName.value = getters_single_staff.value.bank.bank_name;
      accountNumber.value = getters_single_staff.value.bank.account_number;
      branch.value = getters_single_staff.value.bank.branch;

      watch(branch, (c) => {
        disabled.value = watchItems(c, getters_single_staff.value.bank.branch);
      });

      watch(bankName, (b) => {
        disabled.value = watchItems(b, getters_single_staff.value.bank.bank_name);
      });

      watch(accountNumber, (a) => {
        disabled.value = watchItems(
          a,
          getters_single_staff.value.bank.account_number
        );
      });

      const sendData = async () => {
        actionResponse.value = false;

        await observer.value.validate().then((result) => {
          if (result) {
            overlay.value = true;
            let data = {
              id: getters_single_staff.value.id,
              bank_name: bankName.value,
              account_number: accountNumber.value,
              branch: branch.value,
            };
            userBank(data)
              .then(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "success";
                msgBody.value = "Your changes have been saved.";
                msgIcon.value = "mdi-check-circle";
              })
              .catch(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgIcon.value = "mdi-close-circle";
              });
          }
        });
      };

      provide("color", color);

      return {
        ...toRefs(bankForm),
        sendData,
      };
    },
  });
</script>
