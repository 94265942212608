var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fluid":""}},[_c('v-overlay',{attrs:{"absolute":"","value":_vm.overlay,"opacity":0.5}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}}),_c('div',{staticClass:"mt-2 text-center"},[_vm._v("Saving")])],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-toolbar',{attrs:{"primary-title":"","color":"primary","dark":""}},[_vm._v(" Child/Children ")]),_c('v-card-text',[_c('validation-observer',{ref:"observer"},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();}}},[_vm._l((_vm.basketChildren),function(task,i){return [_c('v-row',{key:i,class:{ mycolor: task.duplicate },attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"cols":"12","lg":"5"}},[_c('validation-provider',{attrs:{"name":task.name,"rules":{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","label":"Name of child","error-messages":errors},model:{value:(task.name),callback:function ($$v) {_vm.$set(task, "name", $$v)},expression:"task.name"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('validation-provider',{attrs:{"name":task.dob,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date of birth","readonly":"","outlined":"","hide-details":"auto","error-messages":errors},model:{value:(task.dob),callback:function ($$v) {_vm.$set(task, "dob", $$v)},expression:"task.dob"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}],null,true),model:{value:(task.menuDob),callback:function ($$v) {_vm.$set(task, "menuDob", $$v)},expression:"task.menuDob"}},[_c('v-date-picker',{on:{"input":function($event){task.menuDob = false}},model:{value:(task.dob),callback:function ($$v) {_vm.$set(task, "dob", $$v)},expression:"task.dob"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('validation-provider',{attrs:{"name":task.birthplace,"rules":{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","error-messages":errors,"label":"Birthplace"},model:{value:(task.birthplace),callback:function ($$v) {_vm.$set(task, "birthplace", $$v)},expression:"task.birthplace"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","lg":"2"}},[_c('validation-provider',{attrs:{"name":task.relationship,"rules":"required|alpha"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"hide-details":"auto","outlined":"","error-messages":errors,"label":"Relationship"},model:{value:(task.relationship),callback:function ($$v) {_vm.$set(task, "relationship", $$v)},expression:"task.relationship"}})]}}],null,true)})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","lg":"1"}},[_c('v-slide-y-reverse-transition',[(i + 1 === _vm.basketChildren.length)?_c('v-btn',{attrs:{"icon":"","color":"success","disabled":!_vm.valid},on:{"click":function($event){return _vm.newRow()}}},[_c('v-icon',[_vm._v("mdi-plus-circle")])],1):_vm._e()],1),_c('v-slide-y-reverse-transition',[(i + 1 >= 2)?_c('v-btn',{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.removeRow(i, task.id)}}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1):_vm._e()],1)],1)],1)]})],2)],1)],1),_c('v-card-actions',{staticClass:"ml-2"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"fixed":"","bottom":"","color":"success","disabled":!_vm.valid},on:{"click":_vm.addChildren}},[_vm._v(" save changes ")])],1)],1),(_vm.actionResponse)?_c('Response',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_vm._v(_vm._s(_vm.msgBody))]},proxy:true},{key:"icon",fn:function(){return [_vm._v(_vm._s(_vm.msgIcon))]},proxy:true}],null,false,3394943081)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }