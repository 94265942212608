<template>
  <v-container fluid class="px-0">
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Wait a moment...</div>
    </v-overlay>
    <v-card flat>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent>
            <v-row>
              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Employee ID"
                  rules="required|alpha_dash"
                >
                  <v-text-field
                    hide-details="auto"
                    outlined
                    class="rounded-0"
                    label="Employee ID"
                    v-model="employee_id"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Position"
                  rules="required"
                >
                  <v-autocomplete
                    hide-details="auto"
                    class="rounded-0"
                    outlined
                    :items="getters_staff_positions"
                    item-text="position"
                    item-value="id"
                    label="Position of Appointment"
                    v-model="position"
                    :error-messages="errors"
                    @change="getStaffPositionDetails"
                  ></v-autocomplete>
                </validation-provider>
              </v-col>

              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Staff Division"
                  rules="required"
                >
                  <v-text-field
                    hide-details="auto"
                    outlined
                    class="rounded-0"
                    disabled
                    :value="division"
                    label="Staff Division"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Staff Category"
                  rules="required"
                >
                  <v-text-field
                    hide-details="auto"
                    outlined
                    class="rounded-0"
                    disabled
                    :value="category"
                    label="Staff Category"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>

              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Belongs To"
                  rules="required"
                >
                  <v-autocomplete
                    hide-details="auto"
                    outlined
                    :items="getters_organogram"
                    class="rounded-0"
                    item-value="id"
                    item-text="office_name"
                    label="Belongs To"
                    v-model="organogram_id"
                    :error-messages="errors"
                  >
                    <template #item="{ item }">
                      <span v-if="item.office_type.toLowerCase() == 'office'">
                        <b class="error--text">
                          {{ `${item.office_type} of  the` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                      <span v-else>
                        <b class="black--text">
                          {{ `${item.office_type} of ` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                    </template>
                    <template #selection="{ item }">
                      <span v-if="item.office_type.toLowerCase() == 'office'">
                        <b class="black--text">
                          {{ `${item.office_type} of  the` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                      <span v-else>
                        <b class="black--text">
                          {{ `${item.office_type} of ` }}
                        </b>
                        {{ item.office_name }}
                      </span>
                    </template>
                  </v-autocomplete>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Campus"
                  rules="required"
                >
                  <v-select
                    :items="getters_campus"
                    item-text="campus_name"
                    item-value="id"
                    hide-details="auto"
                    class="rounded-0"
                    label="Campus"
                    data-vv-name="campus"
                    :error-messages="errors"
                    outlined
                    v-model="campus"
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <!-- <v-row>
              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Faculty/Directorate"
                  rules="required"
                >
                  <v-select
                    :items="getters_faculty"
                    item-value="id"
                    item-text="faculty_name"
                    hide-details="auto"
                    class="rounded-0"
                    outlined
                    label="Faculty/Directorate"
                    @change="selectFaculty"
                    :error-messages="errors"
                    data-vv-name="facdir"
                    v-model="facdir"
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="4">
                <validation-provider
                  name="Department"
                  v-slot="{ errors }"
                  :rules="`${
                    facdir !== null && department === null ? 'required' : ''
                  }`"
                >
                  <v-select
                    :items="departments"
                    item-value="id"
                    item-text="department_name"
                    hide-details="auto"
                    label="Department"
                    class="rounded-0"
                    min="1"
                    data-vv-name="department"
                    :error-messages="errors"
                    v-model="department"
                    outlined
                  ></v-select>
                </validation-provider>
              </v-col>
              
            </v-row> -->
            <v-row>
              <v-col cols="12" lg="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Date of birth"
                      rules="required"
                    >
                      <v-text-field
                        v-model="employmentdate"
                        label="Employment date"
                        readonly
                        class="rounded-0"
                        outlined
                        hide-details="auto"
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="employmentdate"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="SSNIT ID"
                  rules="required|alpha_num"
                >
                  <v-text-field
                    hide-details="auto"
                    label="SSNIT ID"
                    outlined
                    :error-messages="errors"
                    class="rounded-0"
                    v-model="ssnit"
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="TIN number"
                  rules="required|alpha_num"
                >
                  <v-text-field
                    hide-details="auto"
                    label="TIN Number"
                    :error-messages="errors"
                    class="rounded-0"
                    v-model="tin"
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-footer app color="transparent">
          <v-btn class="font-weight-bold" color="primary" @click="sendData">
            save changes
          </v-btn>
        </v-footer>
      </v-card-actions>
    </v-card>
    <Response v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import { defineComponent, onMounted, provide, reactive, toRefs } from "vue";
  import { useActions, useGetters } from "vuex-composition-helpers";

  import Response from "@/components/ActionResponse/Response";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { required, alpha_dash, alpha_num } from "vee-validate/dist/rules";
  extend("required", { ...required });
  extend("alpha_num", { ...alpha_num });

  extend("alpha_dash", {
    ...alpha_dash,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { Response, ValidationProvider, ValidationObserver },
    setup() {
      const {
        getCampus,
        userEmployment,
        getOrganogram,
        getStaffPosition,
        getStaffCategory,
        getStaffDivision,
      } = useActions([
        "getCampus",
        "userEmployment",
        "getOrganogram",
        "getStaffPosition",
        "getStaffCategory",
        "getStaffDivision",
      ]);
      const {
        getters_faculty,
        getters_campus,
        getters_single_staff,
        getters_organogram,
        getters_staff_positions,
        getters_staff_categories,
        getters_staff_divisions,
      } = useGetters([
        "getters_faculty",
        "getters_campus",
        "getters_single_staff",
        "getters_organogram",
        "getters_staff_positions",
        "getters_staff_categories",
        "getters_staff_divisions",
      ]);

      const employmentForm = reactive({
        rank: null,
        isDepartment: true,
        valid: true,
        job_title: null,
        facdir: null,
        department: null,
        campus: null,
        employee_id: null,
        ssnit: null,
        tin: null,
        employmentdate: null,
        position: null,
        menu: false,
        category: null,
        division: null,
        organogram_id: null,
        observer: null,
        departments: [],
        overlay: true,
        actionResponse: false,
        msgBody: null,
        msgIcon: null,
        color: null,
        id: null,
      });

      const {
        observer,
        position,
        category,
        division,
        // departments,
        // department,
        campus,
        employee_id,
        ssnit,
        employmentdate,
        tin,
        // facdir,
        overlay,
        msgBody,
        msgIcon,
        color,
        actionResponse,
        organogram_id,
      } = toRefs(employmentForm);

      Promise.all([
        getCampus(),
        getStaffPosition(),
        getOrganogram(),
        getStaffCategory(),
        getStaffDivision(),
      ]).then(() => {
        overlay.value = false;
        position.value = getters_single_staff.value.employment?.staff_position_id;
        category.value =
          getters_single_staff.value.employment?.staff_position?.staff_category.category;
        division.value =
          getters_single_staff.value.employment?.staff_position?.staff_division.division;
        organogram_id.value =
          getters_single_staff.value.employment?.organogram?.id;
      });

      const sendData = async () => {
        actionResponse.value = false;
        await observer.value.validate().then((result) => {
          overlay.value = true;
          if (result) {
            let data = {
              user_id: getters_single_staff.value.id,
              staff_position_id: position.value,
              category: category.value,
              division: division.value,
              organogram_id: organogram_id.value,
              // faculty_id: facdir.value,
              // department_id: department.value,
              campus_id: campus.value,
              employee_id: employee_id.value,
              ssnit_id: ssnit.value,
              tin_number: tin.value,
              employment_date: employmentdate.value,
            };
            userEmployment(data)
              .then(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "success";
                msgBody.value = "Your changes have been saved.";
                msgIcon.value = "mdi-check-circle";
              })
              .catch((e) => {
                console.log(e);
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgIcon.value = "mdi-close-circle";
                switch (e.response.status) {
                  case 422:
                    msgBody.value = e.response.data.message;
                    break;
                  case 403:
                    msgBody.value = e.response.data.message;
                    break;
                  case 404:
                    msgBody.value =
                      "Request Could Not Completed. Try Again Later";
                    break;

                  default:
                    msgBody.value = "Something Went Wrong. Try Again Later";
                    break;
                }
              });
          }
        });
      };

      onMounted(() => {
        campus.value = getters_single_staff.value.employment.campus_id;
        employee_id.value = getters_single_staff.value.employment.employee_id;
        ssnit.value = getters_single_staff.value.employment.ssnit_id;
        employmentdate.value =
          getters_single_staff.value.employment.employment_date;
        tin.value = getters_single_staff.value.employment.tin_number;
        // facdir.value = getters_single_staff.value.employment.faculty_id;
        // department.value = getters_single_staff.value.employment.department_id;

        // selectFaculty(facdir.value);
      });

      const getStaffPositionDetails = (id) => {
        const getCategory = getters_staff_positions.value.find(
          (position) => position.id === id
        );
        category.value = getCategory?.staff_category?.category;
        division.value = getCategory?.staff_division?.division;
      };

      /*  const selectFaculty = async (id) => {
              let faculty = await getters_faculty.value.find(
                (faculty) => faculty.id === id
              );
              if (faculty) departments.value = faculty.departments;
            }; */

      provide("color", color);

      return {
        ...toRefs(employmentForm),
        sendData,
        getters_faculty,
        // selectFaculty,
        getters_campus,
        getters_organogram,
        getters_staff_positions,
        getters_staff_categories,
        getters_staff_divisions,
        getStaffPositionDetails,
      };
    },
  });
</script>
