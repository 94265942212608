<template>
  <v-container fluid class="px-0">
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Wait a moment...</div>
    </v-overlay>
    <Response v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
    <v-card flat>
      <v-card-text>
        <v-slide-y-transition group>
          <v-row
            justify="center"
            align="center"
            v-for="(task, i) in items"
            :key="i"
            class="my-1"
          >
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                :name="task.qualification"
                :rules="{
                  required: true,
                  regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                  distinct: '@' + task.qualification,
                }"
                :vid="'name' + i"
              >
                <v-text-field
                  class="rounded-0"
                  outlined
                  disabled
                  hide-details="auto"
                  label="Qualification and Programme"
                  v-model="task.qualification"
                  :error-messages="errors"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="4">
              <validation-provider
                v-slot="{ errors }"
                :name="task.institution"
                :rules="{
                  required: true,
                  regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                }"
              >
                <v-text-field
                  hide-details="auto"
                  outlined
                  class="rounded-0"
                  disabled
                  v-model.number="task.institution"
                  :error-messages="errors"
                  label="Institution, City and/or County"
                ></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" lg="2">
              <v-dialog ref="dialog" v-model="modal" width="290px">
                <template v-slot:activator="{ on, attrs }">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date"
                    rules="required"
                  >
                    <v-text-field
                      v-model="task.month_year"
                      label="Date"
                      readonly
                      class="rounded-0"
                      v-bind="attrs"
                      outlined
                      disabled
                      hide-details="auto"
                      :error-messages="errors"
                      v-on="on"
                    ></v-text-field>
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="task.month_year"
                  @input="modal = false"
                  type="month"
                  scrollable
                >
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" lg="1">
              <v-btn @click="doAction(task.filename)" large icon color="red">
                <v-icon>mdi-48px mdi-file-pdf-box</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" lg="1" class="text-center">
              <v-slide-x-reverse-transition>
                <v-btn
                  icon
                  small
                  color="red"
                  @click="removeRow(items, i, task.filename)"
                >
                  <v-icon dark> mdi-delete</v-icon>
                </v-btn>
              </v-slide-x-reverse-transition>
            </v-col>
          </v-row>
        </v-slide-y-transition>
      </v-card-text>
    </v-card>

    <v-container fluid>
      <v-btn
        color="primary"
        @click="
          newRow();
          showCert = true;
        "
        class="mr-2 font-weight-bold secondary--text"
      >
        CLICK TO ADD
      </v-btn>
      <v-btn
        color="secondary"
        class="primary--text font-weight-bold"
        v-if="basket.length > 0"
        @click="
          basket = [];
          showCert = false;
        "
      >
        HIDE
      </v-btn>
    </v-container>

    <validation-observer ref="observer" v-show="showCert">
      <v-card flat>
        <v-card-text>
          <v-form @submit.prevent>
            <v-slide-y-transition group>
              <v-row
                align="center"
                justify="center"
                v-for="(task, i) in basket"
                :key="i"
                :class="{ mycolor: task.duplicate }"
              >
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="task.qualification"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      class="rounded-0"
                      outlined
                      hide-details="auto"
                      label="Qualification and Programme"
                      v-model="task.qualification"
                      :error-messages="errors"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="4">
                  <validation-provider
                    v-slot="{ errors }"
                    :name="task.institution"
                    :rules="{
                      required: true,
                      regex: /[a-zA-Z]([\w\W -.]*[a-zA-Z])?$/,
                    }"
                  >
                    <v-text-field
                      hide-details="auto"
                      class="rounded-0"
                      outlined
                      v-model.number="task.institution"
                      :error-messages="errors"
                      label="Institution, City and/or County"
                    ></v-text-field>
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-dialog ref="dialog" v-model="modal" width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <validation-provider
                        v-slot="{ errors }"
                        :name="task.month_year"
                        rules="required"
                      >
                        <v-text-field
                          v-model="task.month_year"
                          label="Date"
                          readonly
                          v-bind="attrs"
                          class="rounded-0"
                          outlined
                          clearable
                          hide-details="auto"
                          :error-messages="errors"
                          v-on="on"
                        ></v-text-field>
                      </validation-provider>
                    </template>
                    <v-date-picker
                      v-model="task.month_year"
                      @input="modal = false"
                      type="month"
                      scrollable
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" lg="1">
                  <validation-provider
                    v-slot="{ errors }"
                    name="Date"
                    rules="required"
                  >
                    <v-file-input
                      prepend-icon="mdi-file-pdf-box"
                      hide-input
                      hide-details="auto"
                      v-model="task.filename"
                      outlined
                      class="rounded-0"
                      :error-messages="errors"
                      type="file"
                      accept=".pdf"
                    />
                  </validation-provider>
                </v-col>
                <v-col cols="12" lg="1" class="text-center">
                  <v-slide-y-reverse-transition>
                    <v-btn
                      icon
                      small
                      dark
                      v-if="i + 1 === basket.length"
                      color="success"
                      @click="newRow()"
                      :disabled="!valid"
                    >
                      <v-icon dark>mdi-plus-circle</v-icon>
                    </v-btn>
                  </v-slide-y-reverse-transition>
                  <v-slide-x-reverse-transition>
                    <v-btn
                      v-if="i + 1 >= 2"
                      dark
                      icon
                      small
                      color="red"
                      @click="removeRow(basket, i)"
                    >
                      <v-icon dark> mdi-delete</v-icon>
                    </v-btn>
                  </v-slide-x-reverse-transition>
                </v-col>
              </v-row>
            </v-slide-y-transition>
          </v-form>
        </v-card-text>
      </v-card>
    </validation-observer>

    <v-row class="px-4">
      <v-col>
        <v-btn
          fixed
          class="font-weight-bold"
          bottom
          color="primary"
          @click="sendData"
          :disabled="!valid"
        >
          Save Changes
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  import { defineComponent, getCurrentInstance, provide, reactive, toRefs, watch } from "vue";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

  import { useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import Api from "@/apis/Api";

  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return value != target;
    },
    message: "Fields can not be the same",
  });

  export default defineComponent({
    components: { Response, ValidationObserver, ValidationProvider },
    setup() {
      const vm = getCurrentInstance()
      const store = vm.proxy.$store;
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const qualificationForm = reactive({
        show: false,
        observer: null,
        valid: true,
        modal: false,
        items: [...getters_single_staff.value.qualification],
        deletedItems: [],
        overlay: false,
        actionResponse: false,
        msgBody: null,
        msgIcon: null,
        color: null,
        fileOptions: [
          {
            title: "View",
            action: "view",
          },
        ],
        basket: [],
        showCert: false,
      });

      const {
        observer,
        items,
        deletedItems,
        overlay,
        msgBody,
        msgIcon,
        color,
        actionResponse,
        valid,
        basket,
      } = toRefs(qualificationForm);

      const sendData = async () => {
        actionResponse.value = false;

        if (valid.value) {
          await observer.value.validate().then((result) => {
            if (result) {
              overlay.value = true;
              const formData = new FormData();
              basket.value.forEach((file, i) => {
                formData.append("pdf[" + i + "]", file.filename);
                delete file["filename"];
                formData.append("qualification[" + i + "]", JSON.stringify(file));
              });
              formData.append("deletedItems", JSON.stringify(deletedItems.value));
              formData.append("user_id", getters_single_staff.value.id);

              Api()
                .post("/user_qualification", formData)
                .then((res) => {
                  actionResponse.value = true;
                  overlay.value = false;
                  store.commit("SET_SINGLE_STAFF", res.data.data);
                  color.value = "success";
                  msgBody.value = "Your changes have been saved.";
                  msgIcon.value = "mdi-check-circle";
                  basket.value = [];
                  items.value = [...getters_single_staff.value.qualification];
                })
                .catch(() => {
                  actionResponse.value = true;
                  overlay.value = false;
                  color.value = "error";
                  msgBody.value = "Something went wrong";
                  msgIcon.value = "mdi-close-circle";
                  basket.value = [];
                });
            }
          });
          return;
        }
      };

      watch(
        basket,
        () => {
          basket.value.forEach((item) => {
            items.value.find((row) => {
              if (
                row.qualification === item.qualification &&
                row.institution === item.institution &&
                row.month_year === item.month_year
              ) {
                item["duplicate"] = true;
                valid.value = false;
                actionResponse.value = true;
                color.value = "error";
                msgBody.value = "Duplicate Found";
                msgIcon.value = "mdi-close-circle";
                return;
              }
              delete item["duplicate"];
              valid.value = true;
            });
          });
        },
        { deep: true }
      );

      const newRow = async () => {
        await observer.value.validate().then((result) => {
          if (result) {
            basket.value.push({
              qualification: null,
              institution: null,
              month_year: null,

              user_id: getters_single_staff.value.id,
              filename: null,
            });
          }
        });
      };

      const removeRow = (array, i, filename) => {
        array.splice(i, 1);
        if (filename) {
          deletedItems.value.push(filename);
          return;
        }
        valid.value = true;
      };

      provide("color", color);

      const doAction = (item) => {
        window.open(
          `http://localhost:8000/${getters_single_staff.value.id}/${item}`,
          "_blank"
        );
      };

      return {
        ...toRefs(qualificationForm),
        sendData,
        newRow,
        removeRow,
        getters_single_staff,
        doAction,
      };
    },
  });
</script>

<style scoped>
  .mycolor {
    border: 1px solid red;
    border-radius: 5px;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
