<template>
  <v-container fluid>
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Saving</div>
    </v-overlay>
    <v-card flat>
      <v-card-title primary-title> Death Gratuity </v-card-title>
      <v-card-text>
        <validation-observer ref="form">
          <v-form @submit.prevent>
            <v-container fluid class="px-0">
              <v-card outlined class="pa-4">
                <v-slide-y-transition>
                  <v-row>
                    <v-col cols="12" lg="12">
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        :rules="{
                          required: true,
                          regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                        }"
                      >
                        <v-text-field
                          hide-details="auto"
                          label="Name of Witness"
                          class="rounded-0"
                          v-model="witnessName"
                          :error-messages="errors"
                          outlined
                        ></v-text-field>
                      </validation-provider>
                    </v-col>
                  </v-row>
                </v-slide-y-transition>
              </v-card>
            </v-container>

            <v-container fluid class="px-0">
              <v-card outlined class="pa-4">
                <validation-observer ref="observer">
                  <v-slide-y-transition>
                    <div class="mt-3">
                      <v-row
                        v-for="(task, i) in basketNominee"
                        justify="center"
                        align="center"
                        :key="i"
                        :class="{ mycolor: task.duplicate }"
                      >
                        <v-col cols="12" lg="4">
                          <validation-provider
                            v-slot="{ errors }"
                            :vid="`name-${i}`"
                            :rules="{
                              required: true,
                              regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                            }"
                            :name="task.name"
                          >
                            <v-text-field
                              outlined
                              hide-details="auto"
                              label="Name of nominee"
                              v-model="task.name"
                              class="rounded-0"
                              :error-messages="errors"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" lg="2">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="task.relationship"
                            :rules="{
                              required: true,
                            }"
                          >
                            <v-select
                              hide-details="auto"
                              outlined
                              v-model="task.relationship"
                              :error-messages="errors"
                              label="Relationship"
                              class="rounded-0"
                              :items="relationshipArray"
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" lg="3">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="task.address"
                            rules="required"
                          >
                            <v-text-field
                              hide-details="auto"
                              outlined
                              v-model="task.address"
                              class="rounded-0"
                              :error-messages="errors"
                              label="Address"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" lg="2">
                          <validation-provider
                            v-slot="{ errors }"
                            :name="task.percentage"
                            rules="required|max_value:100"
                          >
                            <v-text-field
                              hide-details="auto"
                              outlined
                              class="rounded-0"
                              v-model="task.percentage"
                              :error-messages="errors"
                              label="Percent"
                              suffix="%"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" lg="1" class="text-center">
                          <v-slide-y-reverse-transition>
                            <v-btn
                              icon
                              v-if="i + 1 === basketNominee.length"
                              color="success"
                              @click="newRow()"
                              :disabled="!valid"
                              small
                            >
                              <v-icon>mdi-plus-circle</v-icon>
                            </v-btn>
                          </v-slide-y-reverse-transition>
                          <v-slide-x-transition>
                            <v-btn
                              v-if="i + 1 >= 2"
                              icon
                              small
                              color="red"
                              @click="removeRow(i)"
                            >
                              <v-icon> mdi-delete-outline </v-icon>
                            </v-btn>
                          </v-slide-x-transition>
                        </v-col>
                      </v-row>
                    </div>
                  </v-slide-y-transition>
                </validation-observer>
              </v-card>
              <v-row class="mt-3">
                <v-col>
                  <v-btn
                    @click="addNewDeath"
                    color="success"
                    fixed
                    bottom
                    class="font-weight-bold"
                  >
                    save changes
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <Response v-if="actionResponse">
      <template v-slot:header>{{ msgHeader }}</template>
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:body1 v-if="msgBody1">{{ msgBody1 }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import {
    defineComponent,
    onMounted,
    provide,
    reactive,
    toRefs,
    watch,
  } from "vue";
  import Response from "@/components/ActionResponse/Response";

  import { useActions, useGetters } from "vuex-composition-helpers";
  import {
    required,
    email,
    regex,
    alpha_spaces,
    digits,
  } from "vee-validate/dist/rules";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("regex", {
    ...regex,
    message: "{_field_} {_value_} is not valid",
  });

  extend("email", {
    ...email,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  extend("distinct", {
    params: ["target"],
    validate(value, { target }) {
      return value != target;
    },
    message: "Fields can not be the same",
  });

  export default defineComponent({
    components: { Response, ValidationObserver, ValidationProvider },
    setup() {
      const { userGratuity } = useActions(["userGratuity"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const spouseChildrenForm = reactive({
        observer: null,
        overlay: false,
        valid: true,
        basketNominee: [
          {
            name: null,
            percentage: null,
            relationship: null,
            address: null,
          },
        ],
        relationshipArray: [
          "Son",
          "Daughter",
          "Uncle",
          "Auntie",
          "Father",
          "Mother",
          "Niece",
          "Nephew",
        ],
        witnessName: null,
        form: null,
        menuDob: false,
        actionResponse: false,
        color: null,
        msgHeader: null,
        msgBody: null,
        msgIcon: null,
        msgBody1: null,
        total: 0,
      });

      const {
        basketNominee,
        observer,
        witnessName,
        actionResponse,
        color,
        msgHeader,
        msgBody,
        msgBody1,
        msgIcon,
        total,
        valid,
        form,
        overlay,
      } = toRefs(spouseChildrenForm);

      watch(
        () => [...basketNominee.value],
        () => {
          actionResponse.value = false;

          const uniqueItems = [];
          basketNominee.value.filter((item) => {
            if (
              uniqueItems.find(
                (i) =>
                  i.name === item.name &&
                  i.relationship === item.relationship &&
                  i.address === item.address
              )
            ) {
              item["duplicate"] = true;
              valid.value = false;
              actionResponse.value = true;
              color.value = "error";
              msgBody.value = "Duplicate Found";
              msgIcon.value = "mdi-close-circle";
              return true;
            }
            uniqueItems.push(item);
            delete item["duplicate"];
            valid.value = true;
            return false;
          });

          total.value = basketNominee.value.reduce(
            (acc, item) => acc + parseInt(item.percentage),
            0
          );
        },
        { deep: true }
      );

      const findTotal = (number) => {
        if (number > 100 || number === 0) {
          actionResponse.value = true;
          color.value = "error";
          msgHeader.value = "Error";
          msgBody.value = `The percentage should equal to 100.`;
          msgBody1.value = `Total percentage is ${number}`;
          msgIcon.value = "mdi-alert-decagram-outline";
          return true;
        }
      };

      const totalPercent = (number) => {
        if (number !== 100) {
          actionResponse.value = true;
          color.value = "error";
          msgHeader.value = "Error";
          msgBody.value = `The percentage should equal to 100.`;
          msgBody1.value = `Total percentage is ${number}`;
          msgIcon.value = "mdi-alert-decagram-outline";
          return true;
        }
      };

      const newRow = async () => {
        actionResponse.value = false;
        await observer.value.validate().then((result) => {
          actionResponse.value = false;

          if (findTotal(total.value)) return;

          if (result === true) {
            basketNominee.value.push({
              name: null,
              percentage: null,
              relationship: null,
              address: null,
            });
          }
        });
      };

      provide("color", color);

      const removeRow = (i) => {
        basketNominee.value.splice(i, 1);
      };

      onMounted(() => {
        witnessName.value = getters_single_staff.value.death.witness;
        basketNominee.value = getters_single_staff.value.death.nominee;
      });

      const addNewDeath = async () => {
        actionResponse.value = false;
        await form.value.validate().then((result) => {
          if (result) {
            overlay.value = true;
            if (totalPercent(total.value)) return;

            let data = {
              nominee: basketNominee.value,
              witness: witnessName.value,
              user_id: getters_single_staff.value.id,
            };
            userGratuity(data)
              .then(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "success";
                msgBody.value = "Your changes have been saved.";
                msgIcon.value = "mdi-check-circle";
              })
              .catch(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgIcon.value = "mdi-close-circle";
              });
          }
        });
      };

      return {
        ...toRefs(spouseChildrenForm),
        newRow,
        removeRow,
        addNewDeath,
      };
    },
  });
</script>
<style scoped>
  .mycolor {
    border: 1px solid red;
    border-radius: 5px;
    animation: shake-animation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  @keyframes shake-animation {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>
