<template>
  <v-container fluid class="px-0">
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Saving</div>
    </v-overlay>
    <v-card flat>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent ref="form1">
            <v-row>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <v-select
                    v-model="title"
                    outlined
                    :items="titleArray"
                    :error-messages="errors"
                    class="rounded-0"
                    hide-details="auto"
                    label="Title"
                    data-vv-name="Title"
                    required
                  ></v-select>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Gender"
                  rules="required"
                >
                  <v-select
                    hide-details="auto"
                    v-model="gender"
                    :items="genderArray"
                    class="rounded-0"
                    outlined
                    label="Gender"
                    :error-messages="errors"
                    data-vv-name="Gender"
                    required
                  ></v-select>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Other Names"
                  rules="required|alpha_spaces"
                >
                  <v-text-field
                    hide-details="auto"
                    label="Other Names"
                    outlined
                    class="rounded-0"
                    v-model="othernames"
                    :error-messages="errors"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Last name"
                  :rules="{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }"
                >
                  <v-text-field
                    hide-details="auto"
                    label="Last Name"
                    class="rounded-0"
                    v-model="lastname"
                    :error-messages="errors"
                    required
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Date of birth"
                      rules="required"
                    >
                      <v-text-field
                        v-model="dob"
                        label="Date of birth"
                        readonly
                        outlined
                        class="rounded-0"
                        hide-details="auto"
                        :error-messages="errors"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="dob"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <!-- <v-col cols="12" lg="4">
                <validation-provider
                  v-slot="{ errors }"
                  name="Hometown"
                  :rules="{
                    required: true,
                    regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                  }"
                >
                  <v-text-field
                    
                    hide-details="auto"
                    label="Hometown"
                    outlined
                    v-model="hometown"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col> -->
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Nationality"
                  rules="required|alpha_spaces"
                >
                  <v-text-field
                    hide-details="auto"
                    label="Nationality"
                    outlined
                    class="rounded-0"
                    v-model="nationality"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Mobile number"
                  :rules="{
                    required: true,
                    digits: 10,
                    regex: '^(0)\\d{9}$',
                  }"
                >
                  <v-text-field
                    hide-details="auto"
                    outlined
                    label="Mobile Number"
                    :error-messages="errors"
                    v-model="mobileNumber"
                    class="rounded-0"
                    required
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Office number"
                  :rules="{
                    required: true,
                    digits: 10,
                    regex: '^(0)\\d{9}$',
                  }"
                >
                  <v-text-field
                    hide-details="auto"
                    label="Office Number"
                    v-model="officeNumber"
                    class="rounded-0"
                    :error-messages="errors"
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Personal Email"
                  rules="required|email"
                >
                  <v-text-field
                    hide-details="auto"
                    label="Personal Email"
                    v-model="personEmail"
                    :error-messages="errors"
                    class="rounded-0"
                    outlined
                  ></v-text-field>
                </validation-provider>
              </v-col>
              <v-col cols="12" lg="6">
                <validation-provider
                  v-slot="{ errors }"
                  name="Corporate Email"
                  rules="required|alpha_dash"
                >
                  <v-text-field
                    hide-details="auto"
                    label="Corporate Email"
                    :error-messages="errors"
                    v-model="corporateEmail"
                    outlined
                    class="rounded-0"
                    suffix="@gctu.edu.gh"
                  ></v-text-field>
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="Address"
                  rules="required"
                >
                  <v-textarea
                    label="Personal Postal Address"
                    outlined
                    textarea
                    class="rounded-0"
                    v-model="address"
                    :error-messages="errors"
                    hide-details="auto"
                  ></v-textarea>
                </validation-provider>
              </v-col>
            </v-row>

            <v-footer app color="transparent">
              <v-btn color="primary" @click="sendData"> save Changes </v-btn>
            </v-footer>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <Response v-if="actionResponse" class="mb-8">
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>

<script>
  import {
    defineComponent,
    onMounted,
    provide,
    reactive,
    toRefs,
    watch,
  } from "vue";

  import Response from "@/components/ActionResponse/Response";
  import { useActions, useGetters } from "vuex-composition-helpers";

  import { required, email, regex, alpha_spaces } from "vee-validate/dist/rules";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("regex", {
    ...regex,
    message: "{_field_} {_value_} is not valid",
  });

  extend("email", {
    ...email,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { Response, ValidationProvider, ValidationObserver },
    setup() {
      const { userGeneral } = useActions(["userGeneral"]);
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const generalForm = reactive({
        observer: null,
        othernames: null,
        lastname: null,
        title: null,
        select: null,
        gender: null,
        titleArray: ["Mr.", "Mrs.", "Miss.", "Dr.", "Prof."],
        genderArray: ["Male", "Female"],
        hometown: null,
        dob: null,
        valid: true,
        nationality: "",
        transparent: "rgba(255, 255, 255, 0)",
        menu: false,
        mobileNumber: null,
        officeNumber: null,
        personEmail: null,
        corporateEmail: null,
        address: null,
        overlay: false,
        actionResponse: false,
        msgBody: null,
        msgIcon: null,
        color: null,
        id: null,
      });

      const {
        observer,
        title,
        gender,
        othernames,
        lastname,
        dob,
        nationality,
        mobileNumber,
        officeNumber,
        personEmail,
        corporateEmail,
        address,
        overlay,
        msgBody,
        msgIcon,
        color,
        actionResponse,
        id,
      } = toRefs(generalForm);

      watch(
        () => title.value,
        (title) => {
          switch (title) {
            case "Miss.":
              gender.value = "Female";
              break;
            case "Mr.":
              gender.value = "Male";
              break;
            case "Mrs.":
              gender.value = "Female";
              break;
            default:
              gender.value = getters_single_staff.value.gender;
              break;
          }
        }
      );

      const sendData = async () => {
        actionResponse.value = false;
        await observer.value.validate("form1").then((result) => {
          if (result) {
            overlay.value = true;
            let data = {
              id: id.value,
              title: title.value,
              gender: gender.value,
              othernames: othernames.value,
              lastname: lastname.value,
              dob: dob.value,
              nationality: nationality.value,
              address: address.value,
              personal_number: mobileNumber.value,
              office_number: officeNumber.value,
              personal_email: personEmail.value,
              corporate_email: corporateEmail.value + "@gctu.edu.gh",
            };
            userGeneral(data)
              .then(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "success";
                msgBody.value = "Your changes have been saved.";
                msgIcon.value = "mdi-check-circle";
              })
              .catch(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgIcon.value = "mdi-close-circle";
              });
          }
        });
      };

      onMounted(() => {
        id.value = getters_single_staff.value.id;
        title.value = getters_single_staff.value.title;
        gender.value = getters_single_staff.value.gender;
        othernames.value = getters_single_staff.value.othernames;
        lastname.value = getters_single_staff.value.lastname;
        dob.value = getters_single_staff.value.dob;
        nationality.value = getters_single_staff.value.nationality;
        mobileNumber.value = getters_single_staff.value.personal_number;
        officeNumber.value = getters_single_staff.value.office_number;
        personEmail.value = getters_single_staff.value.personal_email;
        corporateEmail.value =
          getters_single_staff.value.corporate_email.substring(
            0,
            getters_single_staff.value.corporate_email.lastIndexOf("@")
          );
        address.value = getters_single_staff.value.address;
      });

      provide("color", color);

      return {
        ...toRefs(generalForm),
        sendData,
      };
    },
  });
</script>
