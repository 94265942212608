<template>
  <v-container fluid>
    <v-overlay absolute :value="overlay" :opacity="0.5">
      <v-progress-circular indeterminate size="64"> </v-progress-circular>
      <div class="mt-2 text-center">Saving</div>
    </v-overlay>
    <v-card flat>
      <v-card-title primary-title> Next Of Kin </v-card-title>
      <v-card-text>
        <validation-observer ref="observer">
          <v-form @submit.prevent>
            <v-container fluid class="px-0">
              <v-card outlined class="pa-4">
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Name"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        label="Next of Kin's name"
                        class="rounded-0"
                        v-model="kinName"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <v-menu
                      v-model="menuDob"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Date of birth"
                          rules="required"
                        >
                          <v-text-field
                            v-model="kinDob"
                            label="Date of birth"
                            readonly
                            class="rounded-0"
                            outlined
                            hide-details="auto"
                            :error-messages="errors"
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="kinDob"
                        @input="menuDob = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Hometown"
                      :rules="{
                        required: true,
                        regex: /[a-zA-Z]([\w\W -]*[a-zA-Z])?$/,
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        class="rounded-0"
                        label="Hometown"
                        :error-messages="errors"
                        v-model="kinHometown"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile number 1"
                      :rules="{
                        required: true,
                        digits: 10,
                        regex: '^(0)\\d{9}$',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        class="rounded-0"
                        label="Mobile number 1"
                        v-model="kinNumber1"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="12" lg="4">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Mobile number 2"
                      :rules="{
                        regex: kinNumber2 ? '^(0)\\d{9}$' : '',
                      }"
                    >
                      <v-text-field
                        hide-details="auto"
                        class="rounded-0"
                        label="Mobile number 2 (optional)"
                        v-model="kinNumber2"
                        :error-messages="errors"
                        outlined
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <v-textarea
                        hide-details="auto"
                        outlined
                        class="rounded-0"
                        label="Address"
                        :error-messages="errors"
                        v-model="kinAddress"
                      ></v-textarea>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-card>
            </v-container>

            <v-row class="mt-3">
              <v-col>
                <v-btn @click="addNewKin" color="success"> save changes </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-card-text>
    </v-card>
    <Response v-if="actionResponse">
      <template v-slot:body>{{ msgBody }}</template>
      <template v-slot:icon>{{ msgIcon }}</template>
    </Response>
  </v-container>
</template>
<script>
  import { defineComponent, onMounted, provide, reactive, toRefs } from "vue";

  import { useActions, useGetters } from "vuex-composition-helpers";
  import Response from "@/components/ActionResponse/Response";
  import {
    required,
    email,
    regex,
    alpha_spaces,
    digits,
  } from "vee-validate/dist/rules";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

  extend("required", {
    ...required,
    message: "{_field_} can not be empty",
  });

  extend("digits", {
    ...digits,
    message: "{_field_} needs to be {length} digits starting with zero(0). ",
  });

  extend("regex", {
    ...regex,
    message: "{_field_} {_value_} is not valid",
  });

  extend("email", {
    ...email,
    message: "{_field_} must be valid",
  });

  extend("alpha_spaces", {
    ...alpha_spaces,
    message: "{_field_} must be valid",
  });

  export default defineComponent({
    components: { Response, ValidationObserver, ValidationProvider },
    setup() {
      const { getters_single_staff } = useGetters(["getters_single_staff"]);
      const { userKin } = useActions(["userKin"]);
      const nextOfKin = reactive({
        observer: null,
        valid: true,
        kinName: "",
        kinDob: "",
        kinAddress: "",
        kinNumber1: "",
        kinNumber2: "",
        kinHometown: "",
        menuDob: false,
        kinNumberError: "",
        overlay: false,
        actionResponse: false,
        msgBody: null,
        msgIcon: null,
        color: null,
      });

      const {
        observer,
        kinName,
        kinDob,
        kinAddress,
        kinNumber1,
        kinNumber2,
        kinHometown,
        overlay,
        actionResponse,
        msgBody,
        msgIcon,
        color,
      } = toRefs(nextOfKin);

      onMounted(() => {
        if (getters_single_staff.value) {
          kinName.value = getters_single_staff.value.kin.kin_name;
          kinDob.value = getters_single_staff.value.kin.kin_dob;
          kinAddress.value = getters_single_staff.value.kin.kin_address;
          kinNumber1.value = getters_single_staff.value.kin.kin_number1;
          kinNumber2.value = getters_single_staff.value.kin.kin_number2;
          kinHometown.value = getters_single_staff.value.kin.kin_hometown;
        }
      });

      const addNewKin = async () => {
        await observer.value.validate().then((result) => {
          actionResponse.value = false;

          if (result) {
            overlay.value = true;
            let data = {
              kin_name: kinName.value,
              kin_dob: kinDob.value,
              kin_address: kinAddress.value,
              kin_number1: kinNumber1.value,
              kin_number2: kinNumber2.value,
              kin_hometown: kinHometown.value,
              user_id: getters_single_staff.value.id,
            };

            userKin(data)
              .then(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "success";
                msgBody.value = "Your changes have been saved.";
                msgIcon.value = "mdi-check-circle";
              })
              .catch(() => {
                actionResponse.value = true;
                overlay.value = false;
                color.value = "error";
                msgBody.value = "Something went wrong";
                msgIcon.value = "mdi-close-circle";
              });
          }
        });
      };
      provide("color", color);
      return {
        ...toRefs(nextOfKin),
        addNewKin,
      };
    },
  });
</script>
